<template>
  <section>
    <div
      class="
        fy-width_100p
        psr
        fy-bottom_0
        fy-margin-t-40
        fy-padding-l-20
        fy-padding-r-20
        fy-padding-t-20
        fy-padding-b-20
      "
      style="background: var(--fy-space-rve-15)"
    >
      <div class="fy-width_100p tac">
        <div class="fy-font-12 fy-margin-t-10">
          <router-link class="fy-text-1 brdn fy-text-1" to="/policies"
            ><span @click="scrolltop" class="fy-text-1"
              >Policy</span
            ></router-link
          >
        </div>
        <div class="fy-font-12 fy-margin-t-10">
          <router-link class="fy-text-1 brdn fy-text-1" to="/terms"
            ><span @click="scrolltop" class="fy-text-1"
              >Terms</span
            ></router-link
          >
        </div>
        <div class="fy-font-12 fy-margin-t-10">
          <a
            class="fy-text-1 brdn fy-text-1"
            href="mailto:fyisshinformation@gmail.com"
            ><span @click="scrolltop">Contact</span></a
          >
        </div>
        <div class="fy-font-12 fy-margin-t-10">
          <router-link class="fy-text-1 brdn fy-text-1" to="/core-values"
            ><span @click="scrolltop" class="fy-text-1"
              >Core-values</span
            ></router-link
          >
        </div>
      </div>
      <div class="fy-width_100p tal fy-margin-t-30">
        <div class="fy-font-15">Media</div>
        <div class="disflex fy-margin-t-10 fy-fit_width">
          <div class="">
            <a href="https://twitter.com/fyish_official" target="_blank"
              ><img
                src="https://cdn-icons-png.flaticon.com/512/733/733635.png"
                alt="Twitter"
                class="fy-width_30px fy-height_30px op5"
            /></a>
          </div>
          <div class="fy-margin-l-10">
            <a href="" target="_blank"
              ><img
                src="https://cdn-icons-png.flaticon.com/512/1384/1384046.png"
                alt="Linkedin"
                class="fy-width_30px fy-height_30px op5"
            /></a>
          </div>
          <div class="fy-margin-l-10">
            <a href="" target="_blank"
              ><img
                src="https://cdn-icons-png.flaticon.com/512/1384/1384028.png"
                alt="Youtube"
                class="fy-width_30px fy-height_30px op5"
            /></a>
          </div>
          <div class="fy-margin-l-10">
            <a href="" target="_blank"
              ><img
                src="https://cdn-icons-png.flaticon.com/512/1051/1051309.png"
                alt="Facebook"
                class="fy-width_30px fy-height_30px op5"
            /></a>
          </div>
        </div>
      </div>

      <div class="fy-width_100p fy-margin-t-15 tar">
        <router-link to="/">
          <div @click="scrolltop" class="fy-font-15 fy-font_w600 fy-text-1">
            fyissh Inc.
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>
<style>
.op5 {
  opacity: 0.5;
}
</style>
<script>
export default {
  methods: {
    scrolltop() {
      document.getElementById(":ht").scrollTo(0, 0);
    },
  },
};
</script>
