<template>
  <div
    id=":ht"
    @scroll="ft"
    class="fy-height_100p fy-width_100p psf fy-auto_scroll"
  >
    <div id=":str" class="fy-width_100p psa fy-top_0">
      <div
        class="
          fy-width_100p
          fy-jc_sb
          disflex
          psr
          fy-top_0
          fy-right_0
          fy-height_65px
          fy-header_bb
          psf
          fy-zi_1
        "
        :class="{
          'fy-portlet-active--bg':
            eader === false || this.$route.name !== undefined,
        }"
      >
        <div
          class="fy-width_auto fy-text-7 fy-margin-l-10"
          style="font-size: 45px"
        >
          <router-link to="/" class="brdn"
            ><span class="fy-text-7">FYISH</span
            ><span class="fy-margin-l-5 fy-font-25 fy-text-1">{{
              this.$route.name
            }}</span></router-link
          >
        </div>
        <div class="fy-width_auto fy-text-7 fy-margin-r-10 fy-padding-10">
          <a href="https://fyish.com" target="_blank"
            ><img
              src="https://cdn-icons-png.flaticon.com/512/1946/1946392.png"
              class="fy-width_45px fy-height_45px op5"
          /></a>
        </div>
      </div>
      <div>
        <router-view />
      </div>

      <div><bot></bot></div>
    </div>
  </div>
</template>

<script>
import bot from "@/components/footer.vue";
export default {
  data() {
    return {
      eader: true,
    };
  },
  components: {
    bot,
  },
  methods: {
    ft() {
      if (this.$route.name === undefined) {
        var testDiv = document.getElementById(":str");
        var t = Math.round(testDiv.getBoundingClientRect().top);
        if (t < -80) {
          this.eader = false;
        } else {
          this.eader = true;
        }
      }
    },
  },
  watch: {
    $route: function () {
      this.ft();
    },
  },
};
</script>
