<template>
  <div class="fy-margin-t-100">
    <div class="fy-width_100p tac">
      <div class="fy-margin-b-20 fy-margin-t-10 fy-font-24 fy-main_logo">
        Core-values
      </div>
      <div class="fy-width_90p">
        <div
          v-for="(c, i) in core"
          :key="i"
          class="fy-text-1 fy-margin-t-10 fy-font-16"
        >
          <span>{{ c.id }}.</span
          ><span class="fy-margin-l-15">{{ c.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      core: [
        {
          id: "1",
          value:
            "Focus on groth doesn't matter that result is successful or unsuccessful.",
        },
        {
          id: "2",
          value:
            "Treat your customer that customer praise our services to others.",
        },
        {
          id: "3",
          value: "Product quality is directly relate with great competition.",
        },
        { id: "4", value: "Always find way for real information." },
        {
          id: "5",
          value: "Problem always open a gate for innovation and growth.",
        },
        { id: "6", value: "Be better then last second." },
        {
          id: "7",
          value: "Always motivate your colleagues for a positive mission.",
        },
        {
          id: "8",
          value:
            "Collect all liable thoughts and experience from person without any matter of person's status.",
        },
      ],
    };
  },
};
</script>
