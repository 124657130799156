<template>
  <div style="margin-top: -60px">
    <div class="fy-width_100p psr fy-height_auto">
      <img
        src="https://images.unsplash.com/photo-1594760412270-9536ebfac7fb?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDJ8fHJlYWxpdHl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
        class="fy-width_100p"
      />
      <div
        class="psa fy-height_100px fy-width_100p tac"
        style="top: calc(50% - 50px)"
      >
        <span class="fy-main_logo" style="font-size: 100px">Reality</span>
      </div>
    </div>

    <div class="ubuntu fy-padding-10">
      <div class="fy-portlet">
        <div
          class="fy-margin-t-25 tac fy-text-1 ubuntu"
          style="font-size: 37px"
        >
          fyish is a home to thousands of peoples who like to help others and
          grow together.
        </div>
      </div>

      <div class="fy-width_100p tac">
        <a href="https://fyish.com" target="_blank">
          <img
            src="https://fyish.com/img/media/icons/logo.svg"
            class="fy-margin-t-20 fy-border-50"
            style="width: 250px; height: 350px"
          />
        </a>
        <div class="fy-text-1 fy-font-30 fy-margin-t-20">Compete Fyish</div>
      </div>

      <div class="fy-margin-t-100">
        <div
          class="
            fy-margin-t-100
            fy-width_100p
            tac
            fy-ts
            fy-font_w600
            fy-main_logo
            ubuntu
          "
          style="font-size: 35px"
        >
          MISSION STATEMENT
        </div>
        <div class="fy-margin-t-30 fy-font-30 tac fy-text-1 ubuntu">
          To innovate the process of connectivity that bring more reality among
          everyone.
        </div>
      </div>
    </div>

  <!--  <div class="fy-width_100p fy-padding-5 tac">
      <div
        class="
          fy-font-25
          fy-main_logo
          tal
          fy-width_100p
          fy-margin-t-10 fy-margin-b-10 fy-padding-l-10
        "
      >
        A word
      </div>

      <p
        class="fy-font-13 fy-text-7 fy-padding-r-40 fy-padding-l-10"
        style="text-align: justify"
      ></p>
      <div
        class="
          fy-margin-t-10
          tar
          fy-width_100p
          fy-padding-r-20 fy-font-15 fy-text-1
        "
      >
        Harshit Rajput
      </div>
    </div>
    <div class="fy-margin-t-30 tar fy-padding-15">
      <div
        class="
          fy-font-25
          fy-main_logo
          tal
          fy-width_100p
          fy-margin-t-10 fy-margin-b-10 fy-padding-l-10
        "
      >
        Group
      </div>
      <div class="disflex fy-fit_width">
        <div
          class="tac fy-padding-5 fy-padding-r-10"
          style="border-right: 1px solid var(--fy-space-rve-50)"
        >
          <div class="fy-font-15 fy-text-7">Harshit Rajput</div>
          <div class="fy-font-13 fy-text-1">Founder of fyish</div>
        </div>
        <div class="tac fy-padding-5 fy-padding-l-10">
          <div class="fy-font-15 fy-text-7">Tushar Rajput</div>
          <div class="fy-font-13 fy-text-1">Co-founder of fyish</div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script></script>
