import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Master from "../views/master.vue";
import Terms from "../views/terms.vue";
import Policy from "../views/policy.vue";
import core from "../views/core.vue";
const routes = [
  {
    name: "",
    path: "/",

    component: Home,
    children: [
      {
        path: "",
        component: Master,
        meta: { title: "About -  Fyish" },
      },
      {
        name: "Terms",
        path: "/terms",
        component: Terms,
        meta: { title: "Terms -  Fyish" },
      },
      {
        name: "Policies",
        path: "/policies",
        component: Policy,
        meta: { title: "Policies -  Fyish" },
      },
      {
        name: "Core",
        path: "/core-values",
        component: core,
        meta: { title: "Core-value -  Fyish" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
