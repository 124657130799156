<template>
  <section class="fy-margin-t-70">
    <div class="fy-padding-r-10 fy-padding-l-10">
      <div
        v-for="(t, index) in terms"
        :key="index"
        class=""
        :class="{
          'fy-margin-t-10 fy-padding-l-20': t.A == 1,
          'fy-margin-t-20': t.A == 0,
        }"
      >
        <div
          v-if="t.H"
          class="fy-ts fy-font-23 fy-text-1"
          :class="{ 'brdn router-link-exact-active': t.c == 1 }"
        >
          {{ t.H }}
        </div>
        <div
          v-if="t.B || t.S"
          class="fy-ta_jc"
          :class="{
            'fy-font-16': t.A == 1,
            'fy-margin-t-10 fy-font-18': t.A == 0,
          }"
        >
          <span v-if="t.P" class="fy-text-1 fy-font-30">. </span>
          <span v-if="t.S" class="fy-text-1">{{ t.S }}: </span>
          <span v-if="t.B" class="fy-text-7">{{ t.B }}</span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      terms: [
        {
          H: "Your personal privacy and the privacy of your data is paramount to us at fyish. We have prepared this Privacy Policy to clearly explain how we protect you and your data at fyish.",
          B: "",
          P: "",
          S: "",
          A: "0",
          C: "1",
        },
        {
          H: "What information do we collect?",
          B: "When you interact with us through the Services, we may collect information from you as described herein.",
          P: "",
          S: "",
          A: "0",
          C: "",
        },
        {
          H: "",
          B: "We collect certain information from you when you voluntarily provide such information. Such information may contain personally identifying information, and may include but not be limited to information such as your username, e-mail address, and any messages or images you transmit using the Services.",
          P: "1",
          S: "Information You Provide",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "We want to inform you that whenever you visit fyish.com, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s IP address, browser version, pages of our services that you visit, the time and date of your visit, the time spent on those pages, and other similar statistics. We may store such information or such information may be included in databases owned and maintained by affiliated third-parties or service providers.",
          P: "1",
          S: "Data We Collect Automatically (Log Data)",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: " Cookies are files with small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive. We employ cookies and similar technologies to keep track of your local computer’s settings such as which account you have logged into and notification settings. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Services.",
          P: "1",
          S: "Cookies",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "We collect information about the actions you take when using the Services. This includes your interactions with content, like voting, saving, hiding, and reporting. It also includes your interactions with other users, such as following, friending, and blocking. We collect your interactions with communities, like your subscriptions or moderator status",
          P: "1",
          S: "Actions you take",
          A: "0",
          C: "",
        },

        {
          H: `What we do with the collected info?`,
          B: "We’re committed to showing you content that’s relevant, interesting and personal to you. To do that, we use your information to provide and improve your experience, including:",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Identify you when you use fyish",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Recommend People, Grewtales, topics or categories you might like based on your activity on fyish.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Respond to your questions or comments.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "`We also have a legitimate interest in making fyish safe and improving our product features so you keep finding the inspiration you want. We all benefit when we use your information to:`",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Suggest other people who have similar interests. For example, if you follow Web Art, we may suggest Artists that you might like.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Provide, maintain, and improve the Services",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Research and develop new services",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Help protect the safety of fyish and our users, which includes blocking suspected spammers and addressing abuse.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Help your friends and contacts find you on fyish.  People can search for your account on fyish using your Phone number or email address.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Work with law enforcement and keep fyish safe. We may get requests for account information from law enforcement, for example, the police or courts.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Review your messages on fyish to detect activity that poses a risk to the safety of you, our community and/or members of the public. ",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Send you updates (such as when certain activity, such as saves or comments, happens on fyish) and news by email or push notification, depending on your settings. ",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: `We have a legitimate interest in delivering ads that are relevant, interesting and personal to you in order to generate revenue. To further these interests, we use the information we collect to:`,
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Decide which ads to show you. For example, if you show an interest in computer parts on fyish, we may show you ads for other computer products. We customize the ad content we show you by identifying your interests based on your onsite and offsite activities, as well as by using information we receive from ad partners or other third parties. Where we use cookies to identify your offsite interests, we’ll get your consent where we need to. Where ad partners or other third parties share information with us about you, we rely on the consent they have already obtained.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Tell our ad partners how their fyish ads are doing, and how to make them better. Some of this information is aggregated. For example, we would report to an advertiser that a certain percentage of people who viewed an ad went on to visit that advertiser's site. In other instances, this information isn’t aggregated. For example, we would let an advertiser know that a particular ad has been saved by certain people.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "How and when we share information",
          B: "",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Services we use to market fyish to you on sites and apps other than fyish, such as Facebook Ads, Google Marketing Platform, Microsoft Advertising, LinkedIn Advertising, The Trade Desk and others from time to time.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Third-party companies, service providers or individuals that we employ to process information on our behalf based on our instructions and for the purposes described in this Privacy Policy. For example, we share data with security consultants to help us get better at identifying spam. Some information we acquire may be collected by third-party providers on our behalf.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "law enforcement agencies or government agencies. We only share information if we believe that disclosure is reasonably necessary to comply with a law, regulation or legal request; to protect the safety, rights or property of the public, any person or fyish; or to detect, prevent or otherwise address fraud, security or technical issues",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Our wholly owned subsidiaries and affiliates. If we were to engage in a merger, acquisition, bankruptcy, dissolution, reorganization, or similar transaction or proceeding that involves the transfer of the information described in this Policy, we would share your information with a party involved in such a process (for example, a potential purchaser).",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "Transferring Your Information",
          B: "",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: `fyish is a worldwide service. By using our products or services, you authorize us to transfer and store your information outside your home country, including in the India, for the purposes described in this policy. The privacy protections and the rights of authorities to access your personal information in such countries may not be equivalent to those of your home country.
`,
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "Choices you have about your info",
          B: "",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Our goal is to give you simple and meaningful choices regarding your information. If you have a fyish account, many of these controls are built directly into fyish or your settings. For example, you can:",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "",
          B: "Edit information in your profile at any time like changing your Name, Username, Profile picture  and Gender.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Link or unlink your fyish account from other services (such as Facebook, Google or Twitter). ",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        { H: "", B: "Edit Grewtales settings ", P: "1", S: "", A: "1", C: "" },

        {
          H: "",
          B: "You can hide your personal like your Date of Birth and Gender from public.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "",
          B: "Close your account at any time. When you close your account, we’ll deactivate it, remove your Posts, and Grewtales from fyish, and delete your account data. Please note that there may be legal reasons for us to keep your data, such as if we receive a law enforcement request asking us to preserve data. We may also retain certain information in our backup systems for a limited period of time, or as required by law.",
          P: "1",
          S: "",
          A: "1",
          C: "",
        },

        {
          H: "Your Rights",
          B: "In fyish Info we believe everyone have right on their personal data so we provide a copy of information fyish has about your account. Before we process a request from you about your personal information, we need to verify the request via your access to your fyish account or to a verified email address associated with your fyish account.",
          P: "",
          S: "",
          A: "0",
          C: "",
        },

        {
          H: "Our Policy on children",
          B: "Children under 13 years of age are not allowed to use fyish.",
          P: "",
          S: "",
          A: "0",
          C: "",
        },
      ],
    };
  },
  methods: {},
};
</script>
