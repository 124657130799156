<template>
  <section class="fy-margin-t-70">
    <div class="fy-padding-r-10 fy-padding-l-10">
      <div v-for="(t, index) in terms" :key="index" class="fy-portlet">
        <div
          class="fy-ts fy-font-25 fy-text-1"
          :class="{
            'router-link-exact-active brdn tac fy-width_100p': index > 6 - 1,
          }"
        >
          {{ t.H }}
        </div>
        <div class="fy-font-18 fy-text-7 fy-ta_jc fy-margin-t-20">
          {{ t.B }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      terms: [
        {
          H: "WHO MAY USE YOUR SERVICE",
          B: `"You may use the Services only if you agree to form a binding contract with fyish and 
are not a person barred from receiving services under the laws of the applicable 
jurisdiction. In any case, you must be at least 13 years old, , to use the Services. If you 
are accepting these Terms and using the Services on behalf of a legal entity, you 
represent and warrant that you are authorized to do so and have the authority to bind 
such entity to these Terms, in which case the words “you” and “your” as used in these 
Terms shall refer to such entity"`,
        },
        {
          H: "PRIVACY",
          B: `"Our privacy describes how we handle the information you provide to us when you use 
our Services. You understand that through your use of the Services you consent to the 
collection and use (as set forth in the Privacy Policy) of this information, including the 
transfer of this information to the India."`,
        },
        {
          H: "GENERAL",
          B: `"You are responsible for your use of the Services and for any Content you provide, 
including compliance with applicable laws, rules, and regulations. You should only 
provide Content that you are comfortable sharing with others."`,
        },
        {
          H: "CONTENT ON THE SERVICE",
          B: `"You may use the Services only if you agree to form a binding contract with fyish and 
are not a person barred from receiving services under the laws of the applicable 
jurisdiction. In any case, you must be at least 13 years old, , to use the Services. If you 
are accepting these Terms and using the Services on behalf of a legal entity, you 
represent and warrant that you are authorized to do so and have the authority to bind 
such entity to these Terms, in which case the words “you” and “your” as used in these 
Terms shall refer to such entity.
Any use or reliance on any Content or materials posted via the Services or obtained by 
you through the Services is at your own risk. We do not endorse, support, represent 
or guarantee the completeness, truthfulness, accuracy, or reliability of any Content or 
communications posted via the Services or endorse any opinions expressed via the 
Services. You understand that by using the Services, you may be exposed to Content 
that might be offensive, harmful, inaccurate or otherwise inappropriate, or in some 
cases, postings that have been mislabeled or are otherwise deceptive. All Content is 
the sole responsibility of the person who originated such Content. We may not monitor 
or control the Content posted via the Services and, we cannot take responsibility for 
such Content."`,
        },
        {
          H: "LIMITATION OF LIABILITY",
          B: `"By using the Services you agree that fyish, its parents, affiliates, related companies, 
officers, directors, employees, agents representatives, partners and licensors, liability is 
limited to the maximum extent permissible in your country of residence."`,
        },
        {
          H: "USING THE SERVICE",
          B: `"Our Services evolve constantly. As such, the Services may change from time to time, at 
our discretion. We may stop (permanently or temporarily) providing the Services or
any features within the Services to you or to users generally. We also retain the right 
to create limits on use and storage at our sole discretion at any time. We may also 
remove or refuse to distribute any Content on the Services, limit distribution or 
visibility of any Content on the service, suspend or terminate users, and reclaim 
usernames without liability to you."`,
        },
        {
          H: "YOUR ACCOUNT",
          B: `"You may need to create an account to use our Services. You are responsible 
for safeguarding your account, so use a strong password and limit its use to this 
account. We cannot and will not be liable for any loss or damage arising from your 
failure to comply with the above. You can control most communications from the 
Services. We may need to provide you with certain communications, such as service 
announcements and administrative messages. These communications are considered 
part of the Services and your account, and you may not be able to opt-out from 
receiving them. If you added your phone number to your account and you later change 
or deactivate that phone number, you must update your account information to help 
prevent us from communicating with anyone who acquires your old number."`,
        },
        {
          H: "YOUR LICENSE USE OF THE SERVICE",
          B: `"fyish gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive 
license to use the software provided to you as part of the Services. This license has the 
sole purpose of enabling you to use and enjoy the benefit of the Services as provided 
by fyish, in the manner permitted by these Terms..
The Services are protected by copyright. Any feedback, or suggestions you may 
provide regarding fyish, or the Services is entirely voluntary and we will be free to use 
such feedback or suggestions as advice for better service in software."`,
        },
      ],
    };
  },
  methods: {},
};
</script>
